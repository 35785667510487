import revive_payload_client_iwFOqNaXqf from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@2.3.3__ioredis@5.4.1__d4lci2qjt6rm45kmbmo4f6osvu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_J6Ha06vLfO from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@2.3.3__ioredis@5.4.1__d4lci2qjt6rm45kmbmo4f6osvu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_EYiL9hey5X from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@2.3.3__ioredis@5.4.1__d4lci2qjt6rm45kmbmo4f6osvu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_0Z0JkmQGdo from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@2.3.3__ioredis@5.4.1__d4lci2qjt6rm45kmbmo4f6osvu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_mSsdMdiGQ4 from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@2.3.3__ioredis@5.4.1__d4lci2qjt6rm45kmbmo4f6osvu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_MoTTeXl3u2 from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@2.3.3__ioredis@5.4.1__d4lci2qjt6rm45kmbmo4f6osvu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_miErq1z4B6 from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@2.3.3__ioredis@5.4.1__d4lci2qjt6rm45kmbmo4f6osvu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_4iSW7iJG8o from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typescript@5.5.4_vue@3.5.8_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/double100/web/mes_website_ui/double100_ui/.nuxt/components.plugin.mjs";
import prefetch_client_wE7SQZHCRU from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@9.11.1_jiti@2.3.3__ioredis@5.4.1__d4lci2qjt6rm45kmbmo4f6osvu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_nR0KST6gvv from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/@nuxt+ui@2.18.6_async-validator@4.2.5_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22._n4wl4dvyvokroijbqmrjkehv34/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_L2n8KzYjkI from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/@nuxt+ui@2.18.6_async-validator@4.2.5_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22._n4wl4dvyvokroijbqmrjkehv34/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_Q6J6qjU5Ul from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/@nuxt+ui@2.18.6_async-validator@4.2.5_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22._n4wl4dvyvokroijbqmrjkehv34/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_1cbaIDrZwQ from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.22.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_6Fim8jDq1C from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_sass@1.79.3_terse_fjaszqtcjhillihxnjaqwl6mhe/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import motion_3VgC1ETpjp from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/@vueuse+motion@2.2.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_typescript@5.5.4_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import switch_locale_path_ssr_AeJDersvts from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_zyEuI1vOfZ from "/root/double100/web/mes_website_ui/double100_ui/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import gsap_client_Z6XKCRtOCT from "/root/double100/web/mes_website_ui/double100_ui/plugins/gsap.client.js";
export default [
  revive_payload_client_iwFOqNaXqf,
  unhead_J6Ha06vLfO,
  router_EYiL9hey5X,
  payload_client_0Z0JkmQGdo,
  navigation_repaint_client_mSsdMdiGQ4,
  check_outdated_build_client_MoTTeXl3u2,
  chunk_reload_client_miErq1z4B6,
  plugin_vue3_4iSW7iJG8o,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wE7SQZHCRU,
  slideovers_nR0KST6gvv,
  modals_L2n8KzYjkI,
  colors_Q6J6qjU5Ul,
  plugin_client_1cbaIDrZwQ,
  plugin_6Fim8jDq1C,
  motion_3VgC1ETpjp,
  switch_locale_path_ssr_AeJDersvts,
  i18n_zyEuI1vOfZ,
  gsap_client_Z6XKCRtOCT
]