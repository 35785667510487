import { default as _91_46_46_46all_93SkgS5MHYjiMeta } from "/root/double100/web/mes_website_ui/double100_ui/pages/[...all].vue?macro=true";
import { default as customer_45storiesmlGfqwOzOTMeta } from "/root/double100/web/mes_website_ui/double100_ui/pages/about/customer-stories.vue?macro=true";
import { default as faqfiVRU3V4FVMeta } from "/root/double100/web/mes_website_ui/double100_ui/pages/about/faq.vue?macro=true";
import { default as _91id_93NUR1f4Mt5wMeta } from "/root/double100/web/mes_website_ui/double100_ui/pages/about/news/[id].vue?macro=true";
import { default as indexqRAAqfN1mJMeta } from "/root/double100/web/mes_website_ui/double100_ui/pages/about/news/index.vue?macro=true";
import { default as contact2ibqD3fUD9Meta } from "/root/double100/web/mes_website_ui/double100_ui/pages/contact.vue?macro=true";
import { default as playgroundB4gVJVaoJMMeta } from "/root/double100/web/mes_website_ui/double100_ui/pages/gsap/playground.vue?macro=true";
import { default as index9AmF6jo3HvMeta } from "/root/double100/web/mes_website_ui/double100_ui/pages/index.vue?macro=true";
import { default as _91id_93zEvfCS8tGQMeta } from "/root/double100/web/mes_website_ui/double100_ui/pages/products/standalone/[id].vue?macro=true";
import { default as indexnuBI3DefZFMeta } from "/root/double100/web/mes_website_ui/double100_ui/pages/products/standalone/index.vue?macro=true";
import { default as _91id_93zbd1Wjwo3RMeta } from "/root/double100/web/mes_website_ui/double100_ui/pages/products/turnkey/[id].vue?macro=true";
import { default as indexALYiuU1R3WMeta } from "/root/double100/web/mes_website_ui/double100_ui/pages/products/turnkey/index.vue?macro=true";
import { default as serviceseTMmUKhFTSMeta } from "/root/double100/web/mes_website_ui/double100_ui/pages/services.vue?macro=true";
export default [
  {
    name: "all",
    path: "/:all(.*)*",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/[...all].vue")
  },
  {
    name: "about-customer-stories",
    path: "/about/customer-stories",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/about/customer-stories.vue")
  },
  {
    name: "about-faq",
    path: "/about/faq",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/about/faq.vue")
  },
  {
    name: "about-news-id",
    path: "/about/news/:id()",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/about/news/[id].vue")
  },
  {
    name: "about-news",
    path: "/about/news",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/about/news/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/contact.vue")
  },
  {
    name: "gsap-playground",
    path: "/gsap/playground",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/gsap/playground.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/index.vue")
  },
  {
    name: "products-standalone-id",
    path: "/products/standalone/:id()",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/products/standalone/[id].vue")
  },
  {
    name: "products-standalone",
    path: "/products/standalone",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/products/standalone/index.vue")
  },
  {
    name: "products-turnkey-id",
    path: "/products/turnkey/:id()",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/products/turnkey/[id].vue")
  },
  {
    name: "products-turnkey",
    path: "/products/turnkey",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/products/turnkey/index.vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/root/double100/web/mes_website_ui/double100_ui/pages/services.vue")
  }
]