<script setup>
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
useHead({
  htmlAttrs: {
    lang: 'en',
  },
  charset: 'utf-8',
  title: 'Double100',
  titleTemplate: title => title !== 'Double100' ? `${title} · Double100` : title,
  meta: [
    { name: 'description', content: '' },
    { property: 'og:image', content: '' },
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon-96x96.png',
    },
  ],
})
</script>

<template>
  <NuxtLoadingIndicator />
  <nuxt-layout>
    <nuxt-page />
  </nuxt-layout>
  <u-notifications />
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
