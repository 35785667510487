export default defineAppConfig({
  title: 'Double100 official website',
  ui: {
    notifications: {
      position: 'top-0 bottom-auto',
    },
    table: {
      base: 'min-w-full table-fixed border border-gray-200',
      divide: 'divide-y divide-gray-200',
      tbody: 'divide-y divide-gray-200',
      tr: {
        base: 'divide-x divide-gray-200',
      },
    },
    variables: {
      light: {
        background: '255 255 255',
        foreground: 'var(--color-gray-700)',
      },
      dark: {
        background: 'var(--color-gray-900)',
        foreground: 'var(--color-gray-200)',
      },
      header: {
        height: '4rem',
      },
    },
    icons: {
      dark: 'i-heroicons-moon-20-solid',
      light: 'i-heroicons-sun-20-solid',
      system: 'i-heroicons-computer-desktop-20-solid',
      search: 'i-heroicons-magnifying-glass-20-solid',
      external: 'i-heroicons-arrow-up-right-20-solid',
      chevron: 'akar-icons:triangle-down-fill',
      hash: 'i-heroicons-hashtag-20-solid',
      menu: 'i-heroicons-bars-3-20-solid',
      close: 'i-heroicons-x-mark-20-solid',
      check: 'i-heroicons-check-circle-20-solid',
    },
    presets: {
      button: {
        primary: {
          color: 'white',
          variant: 'solid',
        },
        secondary: {
          color: 'gray',
          variant: 'ghost',
        },
        input: {
          color: 'white',
          variant: 'outline',
          ui: {
            font: '',
            color: {
              white: {
                outline: 'ring-1 ring-inset ring-gray-300 hover:ring-gray-300 hover:bg-gray-100/50 text-gray-400 hover:text-gray-700 focus-visible:ring-2 focus-visible:ring-primary-500',
              },
            },
          },
        },
      },
    },
    header: {
      wrapper: 'lg:mb-0 lg:border-0',
      links: {
        trailingIcon: {
          base: 'w-4 h-4',
        },
        popover: {
          popper: {
            strategy: 'absolute',
          },
          ui: {
            width: 'w-[16rem]',
          },
        },
      },
      popover: {
        links: {
          active: 'dark:bg-gray-950/50',
          inactive: 'dark:hover:bg-gray-950/50',
        },
      },
      button: {
        icon: {
          open: 'i-ph-list',
          close: 'i-ph-x',
        },
      },
    },
  },
})
